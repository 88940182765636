import { useCallback, useContext, useState } from 'react'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { type AttributeInput } from '@data/shopify/admin/types'
import { AnalyticsEventName, getProductEventPayload } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { CartContext } from '@lib/cart/context'
import { useAddItemsToCart } from '@lib/cart/hooks'
import { LanguageContext } from '@lib/language-context'
import { getOrCreateCombinedListingProduct } from '@lib/product/combined-listing'
import { ShopContext } from '@lib/shop-context'
import { parseShopifyGlobalId } from '@lib/shopify/helpers'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import SimplePortableText from '@components/simple-portable-text'
import ProductAddToCart from './product-add-to-cart'
import ProductCounter from './product-counter'

interface CombinedListingProductActionsProps {
  product: SanityProductFragment
  productIds: number[]
  variantIds: number[]
  size: string
  passepartoutHoleSize?: string
  isEmptyFrame: boolean
  cartAttributes?: AttributeInput[]
}

const CombinedListingProductActions = ({
  product,
  productIds,
  variantIds,
  size,
  passepartoutHoleSize,
  isEmptyFrame,
  cartAttributes,
}: CombinedListingProductActionsProps) => {
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { setIsCreatingProduct } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const { currencyCode } = useContext(ShopContext)

  const [quantity, setQuantity] = useState(1)
  const [isAddToCartError, setIsAddToCartError] = useState(false)

  const addItemsToCart = useAddItemsToCart()

  const handleAddToCart = useCallback(async () => {
    setIsAddToCartError(false)

    let productTitle: string | undefined
    let variantId: number | undefined

    if (isEmptyFrame) {
      // Use product data directly if empty frame
      productTitle = product.title
      variantId = variantIds[0]
    }

    if (!isEmptyFrame && variantIds.length > 1) {
      // Create combined listing product if not empty frame
      setIsCreatingProduct(true)

      const combinedListingProduct = await getOrCreateCombinedListingProduct(
        locale,
        productIds,
        size,
        passepartoutHoleSize
      )
      productTitle = combinedListingProduct?.productTitle
      variantId = combinedListingProduct?.variantId
        ? parseShopifyGlobalId(combinedListingProduct.variantId)
        : undefined

      setIsCreatingProduct(false)
    }

    setIsAddToCartError(!variantId)

    if (!variantId) {
      return
    }

    // Add combined listing product to cart
    const isSuccessful = await addItemsToCart([
      {
        id: variantId,
        quantity,
        attributes: cartAttributes,
      },
    ])

    setIsAddToCartError(!isSuccessful)

    if (!isSuccessful) {
      return
    }

    if (productTitle && variantId) {
      const eventPayload = getProductEventPayload(
        productTitle,
        [
          {
            variantId,
            // TODO: Get variant options for `item_variant`.
            options: [],
            // TODO: Get variant price for `price`.
            price: 0,
            // TODO: Get variant compare price for `discount`.
            comparePrice: 0,
            quantity,
          },
        ],
        currencyCode
      )
      triggerAnalyticsEvent(AnalyticsEventName.AddToCart, eventPayload)
    }
  }, [
    addItemsToCart,
    cartAttributes,
    currencyCode,
    isEmptyFrame,
    locale,
    passepartoutHoleSize,
    product,
    productIds,
    quantity,
    setIsCreatingProduct,
    size,
    triggerAnalyticsEvent,
    variantIds,
  ])

  return (
    <div className="flex flex-col gap-y-5 items-baseline w-full text-center">
      <ProductCounter
        max={10}
        onUpdate={(newQuantity) => setQuantity(newQuantity)}
      />

      <ProductAddToCart
        variant="filled"
        disabled={variantIds.length === 0}
        onClick={handleAddToCart}
        className="max-w-sm w-full"
      >
        {strings.buttonAddToCart}
      </ProductAddToCart>

      {isAddToCartError && (
        <div key="error">
          <Alert error>
            <SimplePortableText
              className="rc rc-alert rc-error"
              content={strings.cartAddToCartErrorMessage}
            />
          </Alert>
        </div>
      )}
    </div>
  )
}

export default CombinedListingProductActions
